import { api } from './api';
export const documentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDocument: builder.query({
            query: (documentId) => `documents/${documentId}`,
            providesTags: ['Documents'],
        }),
        viewDocument: builder.query({
            query: (documentId) => ({
                url: `documents/${documentId}/view`,
                responseHandler: async (response) => {
                    const contentType = response.headers.get('Content-Type');
                    const blob = await response.blob();
                    return URL.createObjectURL(contentType ? new Blob([blob], { type: contentType }) : blob);
                },
            }),
        }),
        getUserDocuments: builder.query({
            query: (userId) => `users/${userId}/documents`,
            providesTags: ['Documents'],
        }),
        searchUserDocuments: builder.query({
            query: ({ userId, s }) => `users/${userId}/documents/search?s=${s}`,
            providesTags: ['Documents'],
        }),
        createUserDocuments: builder.mutation({
            query: ({ userId, files }) => {
                const formData = new FormData();
                for (const file of files) {
                    formData.append('file', file);
                }
                return {
                    url: `users/${userId}/documents`,
                    method: 'POST',
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: ['Documents'],
        }),
        deleteDocument: builder.mutation({
            query: (documentId) => ({
                url: `documents/${documentId}`,
                method: 'DELETE',
                body: {},
            }),
            invalidatesTags: ['Documents'],
        }),
        patchDocument: builder.mutation({
            query: ({ documentId, ...body }) => {
                return {
                    url: `documents/${documentId}`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: ['Documents'],
        }),
        addScreeshotToUserDocuments: builder.mutation({
            query: ({ userId, url }) => {
                return {
                    url: `users/${userId}/documents/screenshot`,
                    method: 'POST',
                    body: { url },
                };
            },
            invalidatesTags: [],
        }),
    }),
});
export const { useGetDocumentQuery, useDeleteDocumentMutation, useCreateUserDocumentsMutation, useGetUserDocumentsQuery, useViewDocumentQuery, usePatchDocumentMutation, useSearchUserDocumentsQuery, useAddScreeshotToUserDocumentsMutation, } = documentApi;
