import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getTitle, Genders } from '../../constants/medicalRecord';
import { translateSpeciality } from '../../constants/practitioner';
import { Titles } from '../../constants/user';
import { mainColor, smallFontSize, bigFontSize, biggerFontSize, } from '../../constants/pdf';
import { getAge } from '../../utils/date';
import Header from './Header';
import Footer from './Footer';
const styles = StyleSheet.create({
    page: {},
    date: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: bigFontSize,
        marginRight: '16px',
        textAlign: 'right',
    },
    body: {
        margin: '16px',
    },
    title: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: bigFontSize,
        marginRight: '16px',
        textAlign: 'right',
        marginBottom: '8px',
    },
    text: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: smallFontSize,
        marginTop: '4px',
    },
    bold: {
        fontFamily: 'Helvetica-Bold',
        fontSize: bigFontSize,
        color: mainColor,
    },
    motive: {
        marginTop: '16px',
        marginBottom: '16px',
        fontFamily: 'Helvetica-Oblique',
        fontSize: biggerFontSize,
        color: mainColor,
        textAlign: 'center',
    },
    block: {
        marginTop: '16px',
    },
    end: {
        marginTop: '24px',
    },
});
const Referral = ({ referral, patient, practitioner, signature }) => {
    const documents = useMemo(() => {
        if (!referral?.documents?.length) {
            return null;
        }
        return (_jsxs(View, { style: styles.block, children: [_jsx(Text, { style: styles.text, children: "Les documents suivants sont joints \u00E0 cette lettre :" }), referral?.documents.map((doc) => (_jsxs(Text, { style: styles.text, children: ["- ", doc.name || doc.label] }, doc.id)))] }));
    }, [referral?.documents]);
    const antecedents = useMemo(() => {
        if (referral?.ignoreAntecedents) {
            return null;
        }
        if (!referral?.antecedents) {
            return null;
        }
        const values = Object.values(referral?.antecedents).filter((v) => !!v);
        if (!values.length) {
            return null;
        }
        return (_jsxs(View, { style: styles.block, children: [_jsx(Text, { style: styles.text, children: "Ce patient pr\u00E9sente les ant\u00E9c\u00E9dents suivants :" }), _jsx(Text, { style: styles.text, children: values.join(' - ') })] }));
    }, [referral?.antecedents, referral?.ignoreAntecedents]);
    const doctorNameWithTitleAndPronoun = useMemo(() => {
        switch (referral?.referredPractitioner?.title) {
            case Titles.Dr:
            case Titles.Pr:
                return `du ${referral?.referredPractitioner?.title} ${referral?.referredPractitioner?.name || '<NAME>'}`;
            case Titles.M:
            case Titles.Mme:
                return `de ${referral?.referredPractitioner?.title} ${referral?.referredPractitioner?.name || '<NAME>'}`;
        }
    }, [referral?.referredPractitioner]);
    if (!patient?.medicalRecord?.birthDate || !practitioner) {
        return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, children: [_jsx(Header, { practitioner: practitioner }), _jsx(View, { style: { margin: '16px' }, children: !patient?.medicalRecord?.birthDate && (_jsx(Text, { style: styles.text, children: "- Veuillez entrer la date de naissance du patient dans son dossier m\u00E9dical" })) })] }) }));
    }
    return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, children: [_jsx(Header, { practitioner: practitioner }), _jsxs(Text, { style: styles.title, children: ["A l'attention\u00A0", _jsx(Text, { style: styles.bold, children: doctorNameWithTitleAndPronoun }), ",\u00A0", !!referral?.referredPractitioner?.speciality &&
                            translateSpeciality(referral?.referredPractitioner?.speciality)] }), _jsxs(Text, { style: styles.date, children: ["Le\u00A0", moment(referral?.date).format('DD/MM/YYYY'), ","] }), _jsxs(View, { style: styles.body, children: [_jsxs(Text, { style: styles.text, children: [referral?.referredPractitioner?.gender === Genders.F
                                    ? 'Chère Consoeur'
                                    : 'Cher Confrère', ","] }), _jsxs(Text, { style: styles.text, children: ["Je vous adresse\u00A0", _jsxs(Text, { style: styles.bold, children: [getTitle(patient?.medicalRecord?.gender), "\u00A0", patient?.firstName, "\u00A0", patient?.lastName] }), ",\u00A0n\u00E9", `${patient.medicalRecord.gender === Genders.F ? '(e)' : ''}`, "\u00A0le\u00A0", moment(patient?.medicalRecord?.birthDate).format('DD/MM/YYYY'), ",\u00A0", getAge(patient?.medicalRecord?.birthDate), " ans, pour la raison suivante:"] }), _jsx(Text, { style: styles.motive, children: referral?.reason || '<REASON>' }), _jsx(Text, { style: styles.text, children: referral?.description || '<DESCRIPTION>' }), documents, antecedents, _jsxs(View, { style: styles.end, children: [_jsxs(Text, { style: styles.text, children: ["En vous remerciant pour\u00A0", patient?.medicalRecord?.gender === Genders.F ? 'elle' : 'lui', ","] }), _jsx(Text, { style: styles.text, children: "Confraternellement," })] })] }), _jsx(Footer, { practitioner: practitioner, signature: signature })] }) }));
};
export default Referral;
