import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { formatId } from '../../utils/string';
const StandardInput = ({ register, id, label, type, cb, rows, placeholder, error, disabled, inputClassName = 'peer block w-full ring-1 ring-gray-200 shadow-sm py-3 px-4 border-main rounded-md placeholder-transparent font-main text-main text-sm', labelClassName = 'absolute pointer-events-none font-main text-main text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic', errorClassName = 'italic font-main text-sm text-red-500 m-1', }) => {
    const errorComponent = error?.message && (_jsx("p", { className: errorClassName, children: error.message }));
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = useCallback(() => {
        setShowPassword((prevState) => !prevState);
    }, [setShowPassword]);
    if (type === 'textarea') {
        return (_jsxs("div", { className: "relative w-full", children: [_jsx("textarea", { ...register(id), onChange: (e) => {
                        register(id).onChange(e);
                        if (typeof cb === 'function') {
                            cb(e.target.value);
                        }
                    }, id: id, rows: rows || 7, placeholder: placeholder, className: inputClassName, "aria-invalid": error ? 'true' : 'false', disabled: !!disabled }), _jsx("label", { id: formatId(id), htmlFor: id, className: labelClassName, children: label }), errorComponent] }));
    }
    return (_jsxs("div", { className: "relative w-full", children: [_jsx("input", { ...register(id), onChange: (e) => {
                    register(id).onChange(e);
                    if (typeof cb === 'function') {
                        cb(e.target.value);
                    }
                }, id: id, type: showPassword ? 'text' : type, placeholder: placeholder, className: inputClassName, "aria-invalid": error ? 'true' : 'false', disabled: !!disabled }), type === 'password' && (_jsx("button", { type: "button", className: "absolute right-3 top-3", onClick: togglePasswordVisibility, children: showPassword ? _jsx(FaEyeSlash, {}) : _jsx(FaEye, {}) })), _jsx("label", { id: formatId(id), htmlFor: id, className: labelClassName, children: label }), errorComponent] }));
};
export default StandardInput;
