export const highlightText = (fullText, searchTerm) => {
    if (!searchTerm) {
        return fullText;
    }
    const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedTerm})`, 'gi');
    return fullText.replace(regex, '<strong>$1</strong>');
};
export const formatId = (id) => id
    .replace(/\s/g, '-')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
