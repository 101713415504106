import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
const DatePickerInput = ({ control, id, label, showTimeSelect, showTimeSelectOnly, error, placeholder = 'jj/mm/aaaa', minDate, maxDate, disabled, inputClassName = 'peer block w-full ring-1 ring-gray-200 shadow-sm py-3 px-4 border-main rounded-md placeholder-transparent font-main text-main text-sm', labelClassName = 'absolute pointer-events-none font-main text-main text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic', }) => {
    const errorComponent = error?.message && (_jsx("p", { className: "flex italic font-main text-sm text-red-500 m-1", children: error.message }));
    const dateFormat = useMemo(() => {
        if (showTimeSelectOnly) {
            return 'HH:mm';
        }
        return showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
    }, [showTimeSelect, showTimeSelectOnly]);
    return (_jsxs("div", { className: "relative w-full", children: [_jsx(Controller, { control: control, name: id, render: ({ field }) => {
                    const value = typeof field.value === 'string'
                        ? moment(field.value).toDate()
                        : field.value;
                    return (_jsx(DatePicker, { showTimeSelect: !!showTimeSelect, showTimeSelectOnly: !!showTimeSelectOnly, className: inputClassName, placeholderText: placeholder, onChange: (date) => field.onChange(date), selected: value, locale: fr, dateFormat: dateFormat, timeFormat: "HH:mm", timeIntervals: 15, isClearable: true, showYearDropdown: true, yearDropdownItemNumber: 100, scrollableYearDropdown: true, minDate: minDate, maxDate: maxDate, disabled: !!disabled }));
                } }), _jsx("label", { htmlFor: id, className: labelClassName, children: label }), errorComponent] }));
};
export default DatePickerInput;
